/* MenuItems.css */
.container-scroller {
    position: relative;
    overflow-x: hidden;
}

.card {
    margin-bottom: 20px;
}

.card-body {
    padding: 20px;
}

.card-title {
    margin-bottom: 20px;
}

.sortable-table th {
    cursor: pointer;
}

.sortable-table th,
.sortable-table td {
    padding: 8px;
    text-align: left;
}

.sortable-table th {
    font-weight: bold;
    background-color: #f8f9fa;
}

.sortable-table tbody tr:nth-child(even) {      
    background-color: #f8f9fa;
}

.sortable-table tbody tr:hover {
    background-color: #e9ecef;
}

/* Custom SweetAlert2 styles */
.swal2-popup {
    font-size: 1.2em; /* Adjust the font size */
    border-radius: 10px; /* Add border radius */
    padding: 20px; /* Add padding */
}

.swal2-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.swal2-content {
    margin-top: 20px;
}

.swal2-confirm {
    background-color: #3085d6;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1em;
}

.swal2-cancel {
    background-color: #d33;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1em;
}

.swal2-icon {
    margin: 20px auto;
}

.swal2-icon.swal2-warning {
    border-color: #f39c12;
    color: #f39c12;
}

.swal2-icon.swal2-error {
    border-color: #e74c3c;
    color: #e74c3c;
}

.swal2-icon.swal2-success {
    border-color: #2ecc71;
    color: #2ecc71;
}

.swal2-icon.swal2-info {
    border-color: #3498db;
    color: #3498db;
}

