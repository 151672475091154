.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li a {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    text-decoration: none;
  }
  
  .pagination li.active a {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination li a:hover {
    background-color: #007bff;
    color: #fff;
  }
  