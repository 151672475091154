/* PharmacyMedicineDetails.css */

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000; /* Adjust transparency as needed */
    border: none;
    color: #fff; /* Adjust button color */
    font-size: 20px; /* Adjust button font size */
    cursor: pointer;
    z-index: 10;
  }
  
  .carousel-button-prev {
    left: 10px; /* Adjust left position as needed */
    height: 40px; /* Adjust button height */
    width: 40px; /* Adjust button width */
    border-radius: 50%; /* Makes the button circular */
  }
  
  .carousel-button-next {
    right: 10px; /* Adjust right position as needed */
    height: 40px; /* Adjust button height */
    width: 40px; /* Adjust button width */
    border-radius: 50%; /* Makes the button circular */
  }
  